import React, { Fragment, useEffect, useState } from 'react';
import {
  CheckboxOptions,
  FieldComponentTypes,
  FieldKeyType,
  FieldValueTypes,
  FormField,
  RadioOptions,
  SelectOptions,
  TypeaheadOptions,
} from '@dtp/membership-service-types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Input, Label, Message, Phone, Select } from '@naf/input';
import { Toggle, LabelVariant } from '@naf/toggle';
import styled from 'styled-components';
import xor from 'lodash/xor';
import get from 'lodash/get';
import { RadioButton } from '@naf/radio-button';
import BlockContent from '../block-content/BlockContent';
// import RadioButton from '../RadioButton';
import { Typeahead } from '../Typeahead';
import { PostalPlace } from '../PostalPlace';
import { VehicleSearchInput } from '../vehicleSearch';
import S from './Styles';
import { FileInput } from '../fileUpload';
import { Email } from '../Email';

interface FormFieldControllerProps {
  fieldComponents: FormField[];
  control: UseFormReturn['control'];
  errors: UseFormReturn['formState']['errors'];
  fieldNamePrefix?: string;
  clearErrors?: UseFormReturn['clearErrors'];
  watch?: UseFormReturn['watch'];
  setValue?: UseFormReturn['setValue'];
  setError?: UseFormReturn['setError'];
}

interface FormFieldWithCondition extends FormField {
  dependentFieldCondition?: string;
}

const FormFieldRender = ({
  field,
  fieldId,
  control,
  errors,
  clearErrors,
  watch,
  setValue,
  setError,
}: {
  field: FormFieldWithCondition;
  fieldId: string;
  control: UseFormReturn['control'];
  errors: UseFormReturn['formState']['errors'];
  clearErrors?: UseFormReturn['clearErrors'];
  watch?: UseFormReturn['watch'];
  setValue?: UseFormReturn['setValue'];
  setError?: UseFormReturn['setError'];
}) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const [fieldToWatch, setFieldToWatch] = useState(null);
  const [fieldToWatchRequiredValue, setFieldToWatchRequiredValue] = useState(null);

  const watchedFieldValue = fieldToWatch ? watch(fieldToWatch) : null;

  useEffect(() => {
    if (field.dependentFieldCondition) {
      const [dependantField, dependantFieldValue] = field.dependentFieldCondition.split('=');
      const shouldWatchField = !!dependantField && !!dependantFieldValue && watch;
      if (shouldWatchField) {
        setFieldToWatch(dependantField);
        setFieldToWatchRequiredValue(dependantFieldValue);
      }
    }
  }, [watch, field]);

  useEffect(() => {
    if (!!fieldToWatch && !!fieldToWatchRequiredValue) {
      const newIsActive = watchedFieldValue === fieldToWatchRequiredValue;
      setIsActive(newIsActive);
      if (!newIsActive && setValue) setValue(field.id, null);
      if (!newIsActive && clearErrors) clearErrors(field.id);
    }
  }, [fieldToWatch, fieldToWatchRequiredValue, watchedFieldValue, setValue, clearErrors, field.id]);

  if (!isActive) return null;

  return (
    <S.FieldWrapper key={fieldId} $isVisible={field?.isVisible !== false}>
      <Controller
        rules={{
          required: field.validation?.required?.value,
          minLength: field.validation?.minLength?.value,
          maxLength: field.validation?.maxLength?.value,
          pattern: field.validation?.pattern?.value,
          max: field.validation?.max?.value,
          min: field.validation?.min?.value,
        }}
        name={fieldId}
        control={control}
        defaultValue={field.initialValue}
        render={({ field: { value, onChange } }) => (
          <>
            {field.component !== FieldComponentTypes.Select && field.component !== FieldComponentTypes.Typeahead && (
              <Label htmlFor={field.id}>{field.label}</Label>
            )}
            {field.component === FieldComponentTypes.TextInput && field.id === 'postalPlace' && (
              <PostalPlace
                id={field.id}
                disabled={field.isReadOnly}
                error={!!errors[fieldId]}
                value={value || ''}
                onChange={onChange}
                width={field.width}
                watch={watch}
                name={fieldId}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            )}
            {field.component === FieldComponentTypes.TextInput && field.id === 'email' && (
              <Email
                id={field.id}
                disabled={field.isReadOnly}
                error={!!errors[fieldId] || !!errors.manualEmailError}
                value={value || ''}
                onChange={onChange}
                width={field.width}
                watch={watch}
                name={fieldId}
                clearErrors={clearErrors}
                setError={setError}
              />
            )}
            {field.component === FieldComponentTypes.TextInput &&
              field.id !== 'email' &&
              field.id !== 'postalPlace' && (
                <S.StyledInput
                  id={field.id}
                  type={field.type === FieldValueTypes.Number ? 'number' : 'text'}
                  disabled={field.isReadOnly}
                  error={!!errors[fieldId]}
                  value={value || ''}
                  onChange={onChange}
                  width={field.width}
                />
              )}
            {field.component === FieldComponentTypes.Number && (
              <Input
                id={field.id}
                type={field.type === FieldValueTypes.Number ? 'number' : 'text'}
                disabled={field.isReadOnly}
                error={!!errors[fieldId]}
                value={value || ''}
                onChange={onChange}
              />
            )}
            {field.component === FieldComponentTypes.TextArea && (
              <S.TextFieldWrapper>
                <Input
                  id={field.id}
                  type={field.type === FieldValueTypes.Number ? 'number' : 'text'}
                  disabled={field.isReadOnly}
                  error={!!errors[fieldId]}
                  value={value || ''}
                  onChange={onChange}
                  placeholder={field.placeHolder}
                  as="textarea"
                  maxCharacters={500}
                />
              </S.TextFieldWrapper>
            )}
            {field.component === FieldComponentTypes.PhoneNumber && (
              <Phone
                id={field.id}
                placeholder={field.placeHolder}
                pattern="/`[0-9]+$/"
                autoComplete="tel"
                disabled={field.isReadOnly}
                error={!!errors[fieldId]}
                countries={[{ name: 'Norge', code: '47' }]}
                selectedCountry={{ name: 'Norge', code: '47' }}
                // TODO: Coordinate phone model with backend
                onChange={(phone: Record<string, string>) => {
                  onChange(phone.number.trim());
                }}
                value={value?.number || value || ''}
              />
            )}
            {field.component === FieldComponentTypes.Typeahead && (
              <Typeahead
                label={field.label}
                dataSource={(field.options as TypeaheadOptions)?.dataSource}
                valueKey={(field.options as TypeaheadOptions)?.valueKey}
                displayKey={(field.options as TypeaheadOptions)?.displayKey}
                error={!!errors[fieldId]} // TODO: Fix error handling
                onChange={onChange}
                placeholder={field.placeHolder}
                width={field.width}
                value={value}
                skipBearerToken
                setValue={setValue}
                id={field.id}
              />
              // <Input disabled={field.isReadOnly} error={!!errors[field.id]} value={value} onChange={onChange} />
            )}
            {field.component === FieldComponentTypes.Toggle && (
              <S.ToggleWrapper>
                <Toggle
                  disabled={field.isReadOnly}
                  name={field.label}
                  onChange={({ value: toggleValue }: { value: boolean }) => {
                    onChange(toggleValue);
                  }}
                  checked={value as boolean}
                  error={!!errors[fieldId]}
                  labelOption={LabelVariant.YesNo}
                />
              </S.ToggleWrapper>
            )}
            {field.component === FieldComponentTypes.Radio && (
              <S.RadioWrapper>
                {(field.options as RadioOptions)?.map((option) => {
                  let variant: 'standard' | 'disabled' | 'error' | undefined = 'standard';
                  if (field.isReadOnly) {
                    variant = 'disabled';
                  } else if (errors[field.id]) {
                    variant = 'error';
                  }
                  return (
                    <Fragment key={`${fieldId}-${option.value}`}>
                      <RadioButton
                        label={option.label}
                        name={field.label}
                        onChange={() => onChange(option.value)}
                        checked={(value as unknown as boolean) === (option.value as unknown as boolean)}
                        disabled={field.isReadOnly}
                        variant={variant}
                      />
                      {((value as unknown as boolean) === (option.value as unknown as boolean) &&
                        option.textIfSelected && (
                          <TextIfSelectedWrapper>
                            <BlockContent value={option.textIfSelected} />
                          </TextIfSelectedWrapper>
                        )) ||
                        null}
                    </Fragment>
                  );
                })}
                {get(errors, fieldId) &&
                  Object.keys(field.validation).map((key) => (
                    <Message key={key} className="errorContainer" error>
                      {field.validation[key]?.message}
                    </Message>
                  ))}
              </S.RadioWrapper>
            )}
            {field.component === FieldComponentTypes.Select && (
              <Select
                label={field.label}
                options={Object.values((field.options as SelectOptions).selectableValues)}
                name={field.label}
                handleSelect={(option: { label: string; value: string }) => {
                  onChange(option.value);
                }}
                selected={(field.options as SelectOptions).selectableValues[field.initialValue as string]}
                disabled={field.isReadOnly}
                error={!!errors[fieldId]}
                width={(field.options as SelectOptions).width}
              />
            )}
            {field.component === FieldComponentTypes.Checkbox &&
              (field.options as CheckboxOptions)?.selectableValues?.map((option) => (
                <S.StyledCheckbox
                  disabled={field.isReadOnly}
                  error={!!errors[field.id]}
                  label={option.label}
                  name={field.label}
                  key={`${fieldId}-${option.value}`}
                  onChange={() => onChange(xor(value as string[], [option.value]))}
                />
              ))}
            {field.component === FieldComponentTypes.RegistrationNumber && (
              <VehicleSearchInput
                error={!!errors[field.id]}
                onChange={onChange}
                disabled={field.isReadOnly || false}
                placeholder={field.placeHolder}
              />
            )}
            {field.component === FieldComponentTypes.FileUpload && (
              <FileInput disabled={field.isReadOnly} error={!!errors[field.id]} name={field.id} onChange={onChange} />
            )}
            {!!errors[fieldId] && field.component !== FieldComponentTypes.Radio && (
              <>
                {errors[fieldId]?.type === 'required' && <Message error>{field.validation?.required?.message}</Message>}
                {errors[fieldId]?.type === 'minLength' && (
                  <Message error>{field.validation?.minLength?.message}</Message>
                )}
                {errors[fieldId]?.type === 'maxLength' && (
                  <Message error>{field.validation?.maxLength?.message}</Message>
                )}
                {errors[fieldId]?.type === 'pattern' && <Message error>{field.validation?.pattern?.message}</Message>}
                {errors[fieldId]?.type === 'min' && <Message error>{field.validation?.min?.message}</Message>}
                {errors[fieldId]?.type === 'max' && <Message error>{field.validation?.max?.message}</Message>}
                {errors[fieldId]?.type === 'manual' && <Message error>{errors[fieldId]?.message}</Message>}
              </>
            )}
          </>
        )}
      />
    </S.FieldWrapper>
  );
};

export const FormFieldController = ({
  fieldComponents,
  control,
  errors,
  fieldNamePrefix,
  clearErrors,
  watch,
  setValue,
  setError,
}: FormFieldControllerProps) => (
  <>
    {fieldComponents.map((field, index) => {
      if (field.key && field.key === FieldKeyType.GroupFields && field.fieldComponents) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <S.GroupFieldWrapper key={`${field.key}-${index}`}>
            {field.fieldComponents.map((groupField) => (
              <FormFieldRender
                field={groupField}
                fieldId={groupField.id}
                control={control}
                errors={errors}
                key={groupField.id}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                setError={setError}
              />
            ))}
          </S.GroupFieldWrapper>
        );
      }
      const fieldId = fieldNamePrefix ? `${fieldNamePrefix}.${field.id}` : field.id;
      return (
        <FormFieldRender
          field={field}
          fieldId={fieldId}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          key={fieldId}
          watch={watch}
          setValue={setValue}
          setError={setError}
        />
      );
    })}
  </>
);

const TextIfSelectedWrapper = styled.div`
  margin-left: 2rem;
`;
