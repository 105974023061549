import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from '@naf/loader';
import { Input } from '@naf/input';
import { spacing, breakpoints } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { useVehicleSearch } from '../hooks/useVehicleSearch';
import { useAuth0Token } from '../hooks/useAuth0Token';

interface Props {
  onChange: (item: any) => void;
  disabled: boolean;
  placeholder?: string;
  error?: boolean;
}

export const VehicleSearchInput = ({ onChange, disabled, placeholder, error }: Props) => {
  const { simpleToken: token } = useAuth0Token();
  const { vehicleInResponse, lookup, isLoading: isLoadingSearch, error: errorSearch } = useVehicleSearch();
  const [licensePlateNumber, setLicensePlateNumber] = useState<string>('');
  const [debounceLicensePlate, setDebounceLicensePlate] = useState<string>('');

  useEffect(() => {
    if (licensePlateNumber.length >= 4) {
      // Wait 500ms after last change before vehicle lookup
      setTimeout(() => {
        setDebounceLicensePlate(licensePlateNumber);
      }, 500);
      if (debounceLicensePlate === licensePlateNumber) {
        lookup({ token, licensePlateNumber });
        onChange(licensePlateNumber);
      }
    }
  }, [lookup, onChange, token, licensePlateNumber, debounceLicensePlate]);

  const handleSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      lookup({ token, licensePlateNumber });
    }
  };

  return (
    <InputWrapper>
      <Input
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        onChange={(number: string) => setLicensePlateNumber(number.trim())}
        onKeyUp={(e: KeyboardEvent) => handleSearch(e)}
      />
      {isLoadingSearch && (
        <SpinnerWrapper>
          <Loader />
        </SpinnerWrapper>
      )}
      {errorSearch && <Text>Vi klarte ikke å finne kjøretøyet. Sjekk om registreringsnummeret stemmer.</Text>}
      {!isLoadingSearch && !errorSearch && vehicleInResponse && (
        <LookupResult>
          {vehicleInResponse.manufactureName ? (
            <>
              <Text>Er dette riktig bil med reg. nr. {vehicleInResponse.licensePlateNumber}?</Text>
              <Text variant={TextVariant.Header3}>
                {vehicleInResponse.manufactureName} {vehicleInResponse.modelName}, {vehicleInResponse.year}
              </Text>
            </>
          ) : (
            <Text>Vi klarte ikke å finne kjøretøyet. Sjekk om registreringsnummeret stemmer.</Text>
          )}
        </LookupResult>
      )}
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  @media (max-width: ${breakpoints.s}) {
    > div {
      width: 100%;
    }
  }
`;

const LookupResult = styled.div`
  margin-top: ${spacing.space12};

  h3,
  p {
    margin: 0;
  }
`;

const SpinnerWrapper = styled.div`
  margin-top: ${spacing.space24};
  width: fit-content;

  @media (max-width: ${breakpoints.s}) {
    margin-left: auto;
    margin-right: auto;
  }
`;
