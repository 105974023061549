import React, { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import S from './Form/Styles';

interface EmailProps {
  disabled?: boolean;
  onChange: (item: any) => void;
  error?: boolean;
  name: string;
  clearErrors?: UseFormReturn['clearErrors'];
  watch?: UseFormReturn['watch'];
  setError?: UseFormReturn['setError'];
  value: string;
  width?: 'Wide' | 'Small';
  id?: string;
}

export const Email = ({
  disabled,
  onChange,
  error = false,
  name,
  clearErrors,
  watch,
  value,
  width,
  setError,
  id,
}: EmailProps) => {
  const emailValue = (watch && watch(name)) || undefined;

  const validate = useMemo(() => () => emailValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i), [emailValue]);

  useEffect(() => {
    if (clearErrors && validate() && name) {
      clearErrors(name);
    }
  }, [validate, clearErrors, name]);

  const handleValidate = () => {
    if (setError && emailValue && !validate()) {
      setError(name, { type: 'manual', message: 'Du må fylle inn en gyldig epost.' });
    } else if (clearErrors && validate()) {
      clearErrors(name);
    }
  };

  return (
    <S.StyledInput
      id={id}
      name={name}
      type="text"
      disabled={disabled}
      error={error}
      value={value}
      onChange={onChange}
      onBlur={() => handleValidate()}
      width={width}
      autoComplete="email"
    />
  );
};
