import React, { useState } from 'react';
import { FileDrop, DocumentList } from '@naf/input';
import Mailbox from '../../../assets/images/Mailbox.svg?url';

interface Props {
  disabled?: boolean;
  error: boolean;
  name: string;
  onChange: (e?: any) => void;
}

export const FileInput = ({ disabled, error, name, onChange }: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <>
      <DocumentList files={files} setFiles={setFiles} />
      <FileDrop
        disabled={disabled}
        error={error}
        onChange={onChange}
        illustration={Mailbox}
        name={name}
        files={files}
        setFiles={setFiles}
        maxFiles={5}
      />
    </>
  );
};
