import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useFetchCity } from '../../hooks/useFetchCity';
import S from '../Form/Styles';

interface Props {
  disabled?: boolean;
  onChange: (item: any) => void;
  error?: boolean;
  name: string;
  placeholder?: string | null;
  clearErrors?: UseFormReturn['clearErrors'];
  watch?: UseFormReturn['watch'];
  setValue?: UseFormReturn['setValue'];
  value: string;
  width?: 'Wide' | 'Small';
  id?: string;
}

export const PostalPlace = ({
  disabled,
  onChange,
  error = false,
  name,
  placeholder,
  clearErrors,
  watch,
  value,
  width,
  setValue,
  id,
}: Props) => {
  const zipCode: string | undefined = (watch && watch('postalCode')) || undefined;
  // TODO: Use data source from API
  const { cityName, getCity, cityError } = useFetchCity(zipCode);

  useEffect(() => {
    if (zipCode && zipCode.length === 4 && getCity) {
      getCity();
    }
  }, [zipCode, getCity]);

  useEffect(() => {
    if (cityName && setValue && clearErrors && name) {
      setValue(name, cityName);
      clearErrors(name);
    }
  }, [setValue, clearErrors, name, cityName, cityError]);

  return (
    <S.StyledInput
      id={id}
      name={name}
      type="text"
      disabled={disabled}
      error={error}
      value={value}
      onChange={onChange}
      width={width}
      placeholder={placeholder}
    />
  );
};
